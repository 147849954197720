/* tslint:disable */

@font-face {
  font-family: "Calibri";
  src: url("../assets/fonts/Calibri/Calibri_Bold.TTF") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Bold_Italic.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Italic.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Light.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Light_Italic.ttf") format("ttf"),
    url("../assets/fonts/Calibri/Calibri_Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

html,
body,
button,
input,
optgroup,
select,
textarea,
html .p-inputtext,
.p-component {
  font-family: "Calibri", Helvetica, sans-serif !important;
}

.p-password input {
  width: 100%;
}
.border-l {
  border-left-width: 1px;
}

.countryCode .p-dropdown {
  height: 50px;
  width: 100%;
}

/* .p-dropdown {
  width: 100%;
} */

.password-eye > i {
  line-height: 1;
}

.p-button:focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav {
  background: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: none;
  border-color: #3b82f6;
  color: #3b82f6;
}

/* .p-tabview .p-tabview-panels {
  margin-top: 15px;
} */

.p-menu .p-menuitem-link {
  padding: 18.3px 1.25rem;
}

.card-profile .scrollable-text {
  max-height: 3.4rem;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
}
.card-profile .scrollable-text::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.filter-custom-width {
  min-width: 120px !important;
}

.site-loader .p-progress-spinner-circle {
  stroke: #1581ed !important;
  animation: none !important;
  stroke-width: 4px !important;
}

.theme-button:hover {
  background: #1581ed !important;
  border-color: #1581ed !important;
}

.theme-outlined {
  border-color: #1581ed !important;
  color: #1581ed !important;
}

.header-split-button .p-menuitem-icon,
.header-split-button .p-menuitem-text {
  @apply text-text-Global !important;
  @apply font-semibold !important;
}

.p-checkbox .p-checkbox-box:hover {
  border-color: #1581ed !important;
}
.p-breadcrumb {
  border: none;
}
.policy-review-table .edit-number-column-cell input {
  @apply py-0 !important;
  @apply px-1 !important;
  @apply text-right !important;
  @apply w-32 !important;
}
.policy-review-table
  .p-datatable
  .p-datatable-thead
  > tr
  > th.p-align-center
  > .p-column-header-content {
  @apply w-full !important;
  @apply text-center !important;
}

.policy-review-table .p-datatable-tbody > tr > td {
  @apply h-11 !important;
}
.policy-review-table .p-calendar {
  @apply h-7 !important;
}
.policy-review-table .p-datatable .p-datatable-thead > tr > th {
  @apply min-w-220 !important;
}

.policy-review-table .p-dropdown {
  @apply h-7 !important;
  @apply w-full !important;
  @apply items-center !important;
}

.policy-review-table .p-column-header-content span,
.plan-split-dollar-table .p-column-header-content span {
  @apply w-full !important;
  @apply text-center !important;
}

.policy-review-table .p-datatable-tbody > tr > td,
.plan-split-dollar-table .p-datatable-tbody > tr > td {
  @apply h-11 !important;
}

.policy-review-table .p-column-header-content,
.plan-split-dollar-table .p-column-header-content {
  @apply whitespace-nowrap !important;
}

#custom-input-color::-moz-color-swatch {
  border: none;
  border-radius: 30% !important;
}

#custom-input-color::-webkit-color-swatch {
  border: 2px solid #ced4da;
  border-radius: 30% !important;
}

#custom-input-color {
  width: 32px;
  height: 36px;
  appearance: none;
  border-radius: 30% !important;
}

.p-datepicker {
  @apply z-[9999999] !important;
}

.p-datepicker-trigger.p-button-icon-only {
  color: unset;
  border: 1px solid #ced4d9;
}

.remove-dataTable .p-menuitem-text {
  color: red !important;
}
.edit-role .p-menuitem-text {
  color: blue !important;
}

.p-toast .p-toast-message-success .p-toast-message-content {
  border-color: #1ea97c;
}

.p-tabview .p-tabview-panels:has(.custom-contact-class),
.custom-contact-class .px-3 {
  padding: 0 !important;
}

.custom-contact-class {
  margin-top: 16px;
}

.p-button:enabled:active {
  background: transparent;
  border-color: #ced4d9;
  color: #6c757d;
}

.p-datepicker-trigger:enabled:hover,
.p-datepicker-trigger:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  border-color: #ced4d9;
  color: #6c757d;
}

.dark .p-selectbutton .p-button.p-highlight {
  background: transparent;
  color: rgba(147, 197, 253, 1);
}

.dark .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover,
.dark .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight) {
  color: #fff;
}

.dark .p-selectbutton .p-button.p-highlight:hover {
  background: transparent;
  color: rgba(147, 197, 253, 1);
}

.dark .custom-divider::before {
  @apply !border-[#314562];
}

.dark .p-tabview .p-tabview-panels {
  border-radius: 0 !important;
}

.dark .p-menu {
  @apply p-0 !important;
  @apply shadow-dark !important;
}

.dark .proposal-steps li:hover {
  @apply bg-transparent !important;
}

.dark .stepper .p-steps .p-steps-item:hover {
  @apply bg-transparent !important;
}

.stepper .p-steps-item {
  min-width: 150px !important;
}

.dark .text-greens {
  @apply text-white !important;
}

.chat-toast svg {
  color: white !important;
}

.dark .p-dropdown-panel .p-dropdown-header {
  @apply bg-blue-1000 !important;
}

.dark .p-accordion .p-accordion-content {
  @apply bg-transparent !important;
  @apply text-white !important;
}

.commission-tracking-datatable .edit-number-column-cell {
  @apply py-0 !important;
  @apply px-1 !important;
  @apply w-full !important;
  @apply text-right !important;
  @apply h-10 !important;
}

.commission-tracking-datatable input {
  @apply text-right !important;
}

.commission-tracking-datatable
  .p-datatable
  .p-datatable-thead
  > tr
  > th.p-align-center
  > .p-column-header-content {
  @apply w-full !important;
  @apply text-center !important;
}

.commission-tracking-datatable .p-datatable-tbody > tr > td {
  @apply h-11 !important;
}

.commission-tracking-datatable .p-datatable .p-datatable-thead > tr > th {
  @apply min-w-220 !important;
}

.commission-tracking-datatable .p-dropdown {
  @apply h-10 !important;
  @apply w-full !important;
  @apply items-center !important;
}

.commission-tracking-datatable .p-column-header-content span,
.plan-split-dollar-table .p-column-header-content span {
  @apply w-full !important;
  @apply text-center !important;
}

.commission-tracking-datatable .p-datatable-tbody > tr > td,
.plan-split-dollar-table .p-datatable-tbody > tr > td {
  @apply h-11 !important;
}

.commission-tracking-datatable .p-column-header-content,
.plan-split-dollar-table .p-column-header-content {
  @apply whitespace-nowrap !important;
}

.aboutUrlregular {
  font-size: 12px;
}

.aboutUrllarge {
  font-size: 14px;
}

.aboutUrlextraLarge {
  font-size: 16px;
}

.max-screen-table .p-datatable-wrapper {
  @apply !max-h-[52vh];
}

.p-datatable > .p-datatable-wrapper,
html {
  scrollbar-width: thin;
}

@supports (-webkit-appearance: none) and (stroke-color: transparent) {
  .grid-center {
    justify-content: center !important;
    align-items: center !important;
  }
}

.table-with-border .p-datatable .p-datatable-thead > tr > th,
.table-with-border .p-datatable .p-datatable-tbody > tr > td {
  border-right-width: 1px !important;
}

.h-154 {
  height: 154px;
}

.top-90 {
  top: 90px;
}

.bottom-75 {
  bottom: 75px;
}
