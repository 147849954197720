@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --cta: 59, 129, 246;
}

.light {
  --non-edit-cell: 245, 245, 245;
  --fallback-bc: #6a6a6a;
}
.dark {
  --non-edit-cell: 7, 20, 38;
  --fallback-bc: #dddddd;
}

@layer components {
  .tooltip-wrapper div.p-tooltip-text {
    color: white !important;
  }

  .p-chip.my-plans.active {
    @apply !text-blue-500;
  }
  .p-chip.my-plans.inactive {
    @apply !text-red-500;
  }
  .p-chip.my-plans.completed {
    @apply !text-green-500;
  }

  .dark .p-toast-message-text * {
    @apply !text-black;
  }

  .dark .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover,
  .dark
    .p-menuitem-link:not(.p-disabled):hover:not(.branding-preview *):not([role="presentation"]) {
    @apply !bg-[#1f395b];
  }

  .set-h-3 input {
    @apply h-12;
  }
  .set-w-3 input {
    @apply w-12;
  }
  .invite-modal-agents .p-confirm-dialog-message {
    @apply ml-0 !important;
  }
  .sidebar .p-sidebar-mask {
    @apply relative;
  }
  .sidebar .p-sidebar-mask .p-sidebar {
    @apply w-full;
  }
  .set-w-3 input {
    @apply w-12;
  }
  .invite-modal-agents .p-confirm-dialog-message {
    @apply ml-0 !important;
  }
  .hidden-input {
    visibility: hidden;
    position: absolute;
    left: -9999px;
    display: none;
  }
  .timeline-opposite-hide .p-timeline-event-opposite {
    display: none;
  }
  .insuranceProduct .p-tabview-nav-container {
    padding-right: calc(100% - 85%);
    @apply border-b-2 border-gray-300;
  }
  .plan-admin-table .p-datatable-thead > tr:first-child > th:first-child,
  .plan-admin-table .p-datatable-thead > tr:nth-child(2) > th:nth-child(1),
  .plan-admin-table .p-datatable-tbody > tr > td:first-child {
    left: 0 !important;
    position: sticky !important;
  }
}

.p-dialog .p-dialog-content,
.p-panel-header,
.chat-root .flex-col-reverse {
  flex-direction: column-reverse;
}

.chat-root .grow {
  flex-grow: 1;
}

.chat-root .border {
  border-width: 1px;
}

.chat-root .border-b {
  border-bottom-width: 1px;
}

.chat-root .py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.chat-root .gap-2\.5 {
  gap: 0.625rem;
}

.chat-root .gap-3\.5 {
  gap: 0.875rem;
}
.chat-root .w-px {
  width: 1px;
}

.chat-root .h-\[100\%\] {
  height: 100%;
}

.chat-root .ps-3 {
  padding-inline-start: 0.75rem;
}

.chat-root .ps-10 {
  padding-inline-start: 2.5rem;
}

.chat-root .rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.chat-root .outline-0 {
  outline-width: 0px;
}

.chat-root .rounded-lg {
  border-radius: 0.5rem;
}

.chat-root .rounded-2xl {
  border-radius: 1rem;
}
.chat-root .rounded-tr-none {
  border-top-right-radius: 0px;
}
.chat-root .rounded-tl-none {
  border-top-left-radius: 0px;
}
.chat-root .truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-root .basis-\[0\%\] {
  flex-basis: 0%;
}
.chat-root .w-64 {
  width: 16rem;
}
@media (min-width: 768px) {
  .chat-root .md\:w-48 {
    width: 12rem;
  }
}
.chat-root .bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.chat-root .p-8 {
  padding: 2rem !important;
}
.chat-root .shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.chat-root .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.chat-root .px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.chat-root .p-4 {
  padding: 1rem !important;
}
.chat-root .px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.chat-root .mt-6 {
  margin-top: 1.5rem !important;
}
.chat-root .mr-4 {
  margin-right: 1rem !important;
}
.chat-root .mr-\[20px\] {
  margin-right: 20px;
}
.chat-root .ml-\[10px\] {
  margin-left: 10px;
}
.chat-root .ml-5 {
  margin-left: 1.25rem !important;
}
.chat-root .rounded {
  border-radius: 0.25rem;
}
.chat-root .whitespace-pre-wrap {
  white-space: pre-wrap;
}
.chat-root .leading-5 {
  line-height: 1.25rem;
}
.chat-root .py-1\.5 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.chat-root .w-\[42px\] {
  width: 42px;
}
.chat-root .aspect-square {
  aspect-ratio: 1 / 1;
}
.chat-root .object-contain {
  object-fit: contain;
}
.chat-root .object-center {
  object-position: center;
}
.chat-root .leading-\[121\%\] {
  line-height: 121%;
}
.chat-root .bg-\[\#1581ED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 129 237 / var(--tw-bg-opacity));
}
.chat-root .py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.chat-root .px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.chat-root .w-\[5px\] {
  width: 5px;
}
.chat-root .h-\[5px\] {
  height: 5px;
}
.chat-root .shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.afr-Datatable span.edit-number-column-cell {
  width: 100%;
}

:root {
  --font-size: 16px;
}

html[data-font="regular"] {
  --font-size: 16px;
}

html[data-font="large"] {
  --font-size: 18px;
}

html[data-font="extraLarge"] {
  --font-size: 20px;
}

html {
  font-size: var(--font-size) !important;
}

.contact-split-button .p-menuitem-link:not(.p-disabled):hover {
  background: transparent !important;
}

.text-balance {
  overflow-wrap: break-word;
}

.chat-root .gap-5 {
  gap: 1.25rem !important;
}

.chat-root .px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.chat-root .pb-6 {
  padding-bottom: 1.5rem !important;
}
.chat-root .pt-8 {
  padding-top: 2rem !important;
}
.chat-root .max-h-96 {
  max-height: 24rem;
}
.chat-root .gap-3 {
  gap: 0.75rem !important;
}
.chat-root .pr-4 {
  padding-right: 1rem !important;
}
.chat-root .mt-\[10px\] {
  margin-top: 10px;
}
.chat-root .pl-\[10px\] {
  padding-left: 10px;
}
.chat-root .pr-\[10px\] {
  padding-right: 10px;
}
.chat-root .p-5 {
  padding: 1.25rem !important;
}
.chat-root .max-w-\[200px\] {
  max-width: 200px;
}
.chat-root .leading-4 {
  line-height: 1rem;
}
.chat-root .leading-3 {
  line-height: 0.75rem;
}
.chat-root .mt-4 {
  margin-top: 1rem !important;
}
.chat-root .max-w-\[500px\] {
  max-width: 500px;
}

.p-menu .p-menuitem-link .p-menuitem-text:not(.branding-preview *) {
  color: inherit !important;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: inherit !important;
  color: inherit !important;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header button {
  color: inherit !important;
}

.more-btn .p-button-label {
  font-weight: 400 !important;
}

.p-tooltip-text {
  @apply !text-white;
}
.p-progressbar-label {
  color: white !important;
}

.my-plan .p-dropdown-trigger {
  width: fit-content !important;
}

.my-plan.p-dropdown,
.my-plan.p-dropdown:not(.p-disabled).p-focus {
  border: 0 !important;
  box-shadow: none !important;
}

.my-plan .p-dropdown-label {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 8px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  @apply bg-dark-activeBtn;
}

.p-menuitem-separator {
  color: inherit !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  @apply dark:!text-[#cdcdcd];
}
.custom-pending-header .p-tabview-nav-container {
  background-color: transparent !important;
}
.completeDetailsCard .p-card-body {
  flex: 1;
}
.completeDetailsCard .p-card-content {
  height: 100%;
}

.branding-preview .branding-preview-table.dark-mode-active .p-datatable-tbody > tr,
.branding-preview .branding-preview-table.dark-mode-active .p-datatable-tbody tr td {
  @apply !bg-dark-primary !text-white;
}

.branding-preview .branding-preview-table.dark-mode-active .p-datatable-thead tr,
.branding-preview .branding-preview-table.dark-mode-active .p-datatable-thead tr th {
  @apply !bg-gray-800 !text-[#c6cdd5];
}

.branding-preview .branding-preview-table.light-mode-active .p-datatable-tbody > tr,
.branding-preview .branding-preview-table.light-mode-active .p-datatable-tbody tr td {
  @apply !bg-light-primary !text-gray-800;
}
.branding-preview .branding-preview-table.light-mode-active .p-datatable-thead tr,
.branding-preview .branding-preview-table.light-mode-active .p-datatable-thead tr th {
  @apply !bg-gray-200 !text-gray-900;
}

.branding-preview .branding-preview-table.light-mode-active.p-paginator button {
  @apply !text-gray-900;
}

.branding-preview .branding-preview-table.dark-mode-active.p-paginator button {
  @apply !text-white;
}

.branding-preview .branding-preview-table.light-mode-active.p-paginator .p-dropdown * {
  @apply !bg-gray-100 !text-gray-900;
}

.branding-preview .branding-preview-table.dark-mode-active.p-paginator .p-dropdown * {
  @apply !bg-gray-800 !text-white;
}

.branding-preview .branding-preview-table.p-paginator .p-dropdown .p-dropdown-label {
  @apply !rounded-tr-none !rounded-br-none;
}

.my-plan-preview.light-mode-active.p-dropdown * {
  @apply !bg-gray-100 !text-gray-600;
}

.my-plan-preview.dark-mode-active.p-dropdown * {
  @apply !text-white;
}

.branding-preview .my-plan.dark-mode-active .p-dropdown-label.p-inputtext {
  @apply !text-gray-400 !opacity-100;
}
/* Custom scrollbar styles */
.scrollbar-hide {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

/* Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-hide::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: transparent;
}

/* Show scrollbar on hover */
.scrollbar-hide:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.dark .scrollbar-hide:hover {
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.scrollbar-hide:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.dark .scrollbar-hide:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}
@layer utilities {
  .bg-non-edit-cell {
    background-color: rgba(var(--non-edit-cell)); /* your desired color */
  }
  .row-custom-class {
    @apply !bg-non-edit-cell;
  }

  .bg-edit-cell {
    @apply bg-white dark:bg-[#213147];
  }

  .max-h-[52vh] {
    max-height: 52vh;
  }
}

.p-datatable .p-datatable-thead > tr > th:not(.p-sortable-column.p-highlight) {
  @apply dark:bg-[#142134];
}

.text-gray-700 {
  @apply dark:!text-gray-300;
}

* {
  @apply border-gray-400 dark:border-gray-700;
}

.card-content-itm .p-card .p-card-content {
  @apply p-0 !important;
}

.attention-card {
  box-shadow: 0px 2px 12px 0px #0000001a;
}
:is(.dark .dark\:attention-card) {
  box-shadow: 0px 2px 12px 0px #ffffff1a;
}
.p-datatable-wrapper {
  @apply scrollbar-hide;
}
.overflow-y-auto,
.overflow-auto,
.p-multiselect-items-wrapper,
.p-dropdown-items-wrapper,
.p-dialog-content {
  @apply scrollbar-hide;
}

.valuation-custom-option {
  margin: -0.75rem -1.25rem !important;
  padding: 15px;
}
.p-column-header-content.p-frozen-column {
  margin: -0.03rem !important;
  padding-top: 0.7rem !important;
}
.inherit-menuIcon-color.p-menuitem-icon {
  color: inherit !important;
  /* Use !important to override default styles */
}
